<template>
  <v-container fluid class="down-top-padding">
    <div id="studentPage" class="page">
      <div class="page__header">
        <Toolbar @toggleFilters="handleToggleFilters" />
      </div>
      <div class="page__body pa-4">
        <div v-show="searchBoxdialog">
          <v-row class="my-0">
            <v-col md="12">
              <Filters
                :studentTypes="studentTypes"                
                :lessonScheduleTypes="lessonScheduleTypes"                
                :genders="genders"
                @search="filtersChanged"
                @clear="filtersChanged"
              />
            </v-col>
          </v-row>
        </div>

        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @optionsChanged="handleOptionsChanged"
          @toggleStudentArchiving="handletoggleStudentArchiving"
        />
      </div>
      <ConfirmationPopup
        id="student-archive-modal"
        title="Tələbənin arxivləməsi"
        v-if="studentToArchive"
        :dialog="confirmationDialog"
        @confirm="handleArchiveConfirmation(studentToArchive.id)"
        @cancel="handleArchiveCancellation"
        :confirmText="
          studentToArchive.isArchived ? 'Arxivdən çıxart' : 'Arxiv et'
        "
      >
        <span class="font-weight-bold">
          {{
            `"${studentToArchive.firstName +
              " " +
              studentToArchive.lastName}"`
          }}
        </span>
        haqqında məlumatları
        {{
          studentToArchive.isArchived ? "arxivdən çıxartmağa" : "arxivləməyə"
        }}
        əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import List from "./components/List/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Filters/index.vue";
import filtersPopup from "@/mixins/filters-popup.js";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../event-bus";

export default {
  mixins: [filtersPopup],
  components: {
    List,
    Toolbar,
    Filters,
    ConfirmationPopup,
  },
  data() {
    return {
      confirmationDialog: false,
      studentToArchive: null,
      searchBoxdialog: false,
      selectedUser: null,
      page: 1,
      isFetchingItems: false,
      totalCount: 0,
      itemsPerPage: 25,
      sortedBy: null,
      sortedDesc: null,
      items: [],
      studentTypes: [],      
      lessonScheduleTypes: [],      
      genders: [],
      filters: null,
    };
  },
  async created() {
    this.fetchStudents();    
    this.fetchLessonScheduleTypes();
    this.fetchStudentTypes();    
    this.fetchHRGenders();
  },
  methods: {    
    async fetchLessonScheduleTypes() {
      this.lessonScheduleTypes = await API.fetchLessonScheduleTypes();
    },
    async fetchStudentTypes() {
      this.studentTypes = await API.fetchStudentTypes();
    },    
    async fetchHRGenders() {
      this.genders = await API.fetchHRGenders();
    },    
    getProfilePhotos() {
      this.items.forEach((item) => {
        let response = API.fetchStudentProfilePhoto(item.id, 56, 0);
        response
          .then((res) => {
            this.$set(item, "profile", res);
            item.profile = res;
          })
          .catch(() => {
            item.profile = null;
          });
      });
    },
    fetchStudents() {
      this.isFetchingItems = true;
      API.fetchStudents({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        sortedBy: this.sortedBy,
        sortedDesc: this.sortedDesc,
        isArchived: false,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
          this.getProfilePhotos();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Tələbələrin siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleOptionsChanged(value) {
      if (typeof value.sortedBy === "undefined") {
        this.sortedBy = null;
      } else {
        this.sortedBy = value.sortedBy;
      }
      if (typeof value.sortedDesc === "undefined") {
        this.sortedDesc = null;
      } else {
        this.sortedDesc = value.sortedDesc;
      }

      this.itemsPerPage = value.itemsPerPage;
      this.page = value.page;
      this.fetchStudents();
    },
    handleToggleFilters(value) {
      this.searchBoxdialog = value;
    },
    filtersChanged(filters) {
      if (JSON.stringify(this.filters) !== JSON.stringify(filters)) {
        this.filters = filters;
        this.page = 1;
        this.fetchStudents();
      }
    },
    handletoggleStudentArchiving(item) {
      this.studentToArchive = item;
      this.confirmationDialog = true;
    },
    handleArchiveConfirmation(id) {
      API.toggleStudentArchiving(id)
        .then(() => {
          bus.$emit("notificationMessage", {
            color: "success",
            text: this.studentToArchive.isArchived
              ? "Tələbə arxivdən çıxarıldı."
              : "Tələbə arxiv olundu.",
          });
          this.fetchStudents();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: this.studentToArchive.isArchived
              ? "Tələbənin arxivdən çıxarılması mümkün olmadı."
              : "Tələbənin arxiv olunması mümkün olmadı.",
          });
        })
        .finally(() => {
          this.confirmationDialog = false;
          this.studentToArchive = null;
        });
    },
    handleArchiveCancellation() {
      this.confirmationDialog = false;
      this.studentToArchive = null;
    },
  },
};
</script>
