<template>
  <div class="rounded border-all my-0 py-0">
    <div class="pt-0 px-4 pb-0">
      <v-row class="my-0 py-0">
        <v-col cols="4" class="mb-3">
          <v-text-field
            type="text"
            label="Ad Soyad"
            filled
            dense
            v-model="filters.fullName"
            hide-details
          ></v-text-field>
        </v-col>        

        <v-col cols="4" class="py-0">
          <v-row class="my-0">
            <v-col cols="6">
              <v-text-field
                type="number"
                label="Yaş(dan)"
                filled
                dense
                v-model="filters.minAge"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="number"
                label="Yaş(a)"
                filled
                dense
                v-model="filters.maxAge"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4" class="mb-3">
          <v-select
            label="Dərs vaxtı"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            :items="lessonScheduleTypes"
            v-model="filters.lessonScheduleTypeId"
          />
        </v-col>

        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Tələbə təsnifatı</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.studentTypeId"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio
                    :label="studentType.value"
                    :value="studentType.id"
                    v-for="(studentType, index) in studentTypes"
                    :key="index"
                  ></v-radio>
                  <v-radio label="Hamısı" value="all" key="all"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
        
        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Statusu</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.isArchived"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio label="Aktiv" value="false"></v-radio>
                  <v-radio label="Arxiv" value="true"></v-radio>
                  <v-radio label="Hamısı" value="null"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
       
        <v-col cols="4" class="py-0">
          <div class="px-3">
            <v-row class="flex-column my-0">
              <v-col cols="12" class="py-2">
                <span class="caption input-color ls-0">Cinsiyyət</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="filters.genderId"
                  row
                  class="mt-0"
                  hide-details
                  dense
                >
                  <v-radio
                    :label="gender.value"
                    :value="gender.id"
                    v-for="(gender, index) in genders"
                    :key="index"
                  ></v-radio>
                  <v-radio label="Hamısı" value="all" key="all"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="py-3 px-4 d-flex justify-end border-top">
      <v-btn
        @click="clear"
        class="clear-filters mr-3"
        depressed
        color="gray lighten-3"
      >
        <span class="gray--text text--darken-3 text-body-2 font-weight-medium"
          >Təmizlə</span
        >
      </v-btn>
      <v-btn @click="search" color="primary" depressed> Axtar </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    studentTypes: {
      type: Array,
      required: true,
    },   
    lessonScheduleTypes: {
      type: Array,
      required: true,
    },    
    genders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        fullName: null,       
        minAge: null,
        maxAge: null,
        lessonScheduleTypeId: null,
        studentTypeId: null,        
        isArchived: "false",        
        genderId: null,
      },
      checkedFilters: {},
    };
  },
  methods: {
    search() {
      this.createFilterModel();

      this.$emit("search", this.checkedFilters);
    },
    clear() {
      this.filters = {
        fullName: null,        
        minAge: null,
        maxAge: null,
        lessonScheduleTypeId: null,
        studentTypeId: null,
        isArchived: "false",
        genderId: null,
      };
      this.createFilterModel();

      this.$emit("clear", this.checkedFilters);
    },
    createFilterModel() {
      this.checkedFilters = {};

      if (this.filters.fullName) {
        this.checkedFilters.fullName = this.filters.fullName;
      }

      if (this.filters.minAge) {
        this.checkedFilters.minAge = this.filters.minAge;
      }

      if (this.filters.maxAge) {
        this.checkedFilters.maxAge = this.filters.maxAge;
      }

      if (this.filters.lessonScheduleTypeId) {
        this.checkedFilters.lessonScheduleTypeId = this.filters.lessonScheduleTypeId;
      }

      this.checkedFilters.isArchived = JSON.parse(this.filters.isArchived);

      

      if (this.filters.genderId && this.filters.genderId.toString() !== "all") {
        this.checkedFilters.genderId = this.filters.genderId;
      }
    },
  },
};
</script>
